import { RouteRecordRaw } from 'vue-router'

const siteName = 'Aspect Data Portal'
const formFullTitle = (pageTitle: string) => {
  return `${pageTitle} - ${siteName}`;
}

// A nested array of accessible routes
// - Top-level elements are accessed by https://url/path (e.g., https://url/login)
// - Elements included as children to top-level elements are accessed by https://url/path/path (e.g., https://url/ordering-and-fulfillment/part-ordering)
export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { title: formFullTitle('Home') }
  },
  {
    path: '/error-perm',
    name: 'error-perm',
    component: () => import('../views/InsufficientPermissionView.vue'),
    meta: { title: formFullTitle('Insufficient Permission') }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: { title: formFullTitle('Login') }
  },
  {
    path: '/ordering-and-fulfillment',
    name: 'ordering-and-fulfillment',
    component: () => import ('../views/manufacturing/ManufacturingOrderingAndFulfillmentFormsOverview.vue'),
    meta: {
      title: formFullTitle('Ordering and Fulfillment'),
    },
    children: [
      // NOTE: commented out the following rediection because a user who doesn't have access permission to the hard-coded
      // redirection destination would be led to the insufficient permission error page
      // {
      //   path: '', // if the child path is unspecified, redirect to the hard-coded child
      //   redirect: '/ordering-and-fulfillment/part-ordering'
      // },
      {
        path: 'part-ordering',
        component: () => import ('../views/manufacturing/PartOrderingView.vue'),
        meta: {
          title: formFullTitle('Part Ordering')
        }
      },
      {
        path: 'part-fulfillment',
        component: () => import ('../views/manufacturing/PartFulfillmentView.vue'),
        meta: {
          title: formFullTitle('Part Fulfillment')
        }
      },
      {
        path: 'printhead-ordering',
        component: () => import ('../views/manufacturing/PrintheadOrderingView.vue'),
        meta: {
          title: formFullTitle('Printhead Ordering')
        }
      },
      {
        path: 'printhead-fulfillment',
        component: () => import ('../views/manufacturing/PrintheadFulfillmentView.vue'),
        meta: {
          title: formFullTitle('Printhead Fulfillment')
        }
      },
      {
        path: 'printhead-required-inventory',
        component: () => import ('../views/manufacturing/PrintheadRequiredInventoryView.vue'),
        meta: {
          title: formFullTitle('Printhead Required Inventory')
        }
      },
      {
        path: 'part-required-inventory',
        component: () => import ('../views/manufacturing/PartRequiredInventoryView.vue'),
        meta: {
          title: formFullTitle('Part Required Inventory')
        }
      },
      {
        path: 'part-required-inventory-overview-data',
        component: () => import ('../views/manufacturing/datatables/PartRequiredInventoryOverview.vue'),
        meta: {
          title: formFullTitle('Part Required Inventory Overview')
        }
      },
      {
        path: 'part-order-overview-data',
        component: () => import ('../views/manufacturing/datatables/PartOrderOverview.vue'),
        meta: {
          title: formFullTitle('Part Order Overview')
        }
      },
      {
        path: 'printhead-order-overview-data',
        component: () => import ('../views/manufacturing/datatables/PrintheadOrderOverview.vue'),
        meta: {
          title: formFullTitle('Printhead Order Overview')
        }
      },
      {
        path: 'printhead-required-inventory-overview-data',
        component: () => import ('../views/manufacturing/datatables/PrintheadRequiredInventoryOverview.vue'),
        meta: {
          title: formFullTitle('Printhead Required Inventory Overview')
        }
      },
      {
        path: 'printhead-inventory-overview-data',
        component: () => import ('../views/manufacturing/datatables/PrintheadInventoryOverview.vue'),
        meta: {
          title: formFullTitle('Printhead Inventory Overview')
        }
      }
    ]
  },
  {
    path: '/manufacturing-management',
    name: 'manufacturing-management',
    component: () => import ('../views/manufacturing/ManufacturingManagementFormsOverview.vue'),
    meta: {
      title: formFullTitle('Manufacturing Management'),
    },
    children: [
      // NOTE: commented out the following rediection because a user who doesn't have access permission to the hard-coded
      // redirection destination would be led to the insufficient permission error page
      // {
      //   path: '', // if the child path is unspecified, redirect to the hard-coded child
      //   redirect: '/manufacturing-management/new-printhead-form'
      // },
      {
        path: 'new-part-manufacturing-version',
        component: () => import ('../views/manufacturing/PartManufacturingVersionManageView.vue'),
        meta: {
          title: formFullTitle('Manage Part Manufacturing Version')
        }
      },
      {
        path: 'new-printhead-form',
        component: () => import ('../views/manufacturing/NewPrintheadFormView.vue'),
        meta: {
          title: formFullTitle('New Printhead Form')
        }
      },
      {
        path: 'part-manage-active-state-form',
        component: () => import ('../views/manufacturing/PartManageActiveStateFormViewView.vue'),
        meta: {
          title: formFullTitle('Manage Active Parts')
        }
      },
      {
        path: 'printhead-manage-active-state-form',
        component: () => import ('../views/manufacturing/PrintheadManageActiveStateFormViewView.vue'),
        meta: {
          title: formFullTitle('Manage Active Printheads')
        }
      },
      {
        path: 'printhead-action-procedure-form',
        component: () => import ('../views/manufacturing/PrintheadActionProcedureFormView.vue'),
        meta: {
          title: formFullTitle('Printhead Action Procedure Form')
        }
      },
      {
        path: 'part-action-procedure-form',
        component: () => import ('../views/manufacturing/PartActionProcedureFormView.vue'),
        meta: {
          title: formFullTitle('Part Action Procedure Form')
        }
      },
      {
        path: 'mould-action-procedure-form',
        component: () => import ('../views/manufacturing/MouldActionProcedureFormView.vue'),
        meta: {
          title: formFullTitle('Mould Action Procedure Form')
        }
      },
      {
        path: 'im-supplier-table',
        component: () => import ('../views/manufacturing/datatables/ImSupplierTable.vue'),
        meta: {
          title: formFullTitle('IM Suppliers'),
        },
      },
      {
        path: 'new-im-lot-form',
        component: () => import ('../views/manufacturing/NewImLotForm.vue'),
        meta: {
          title: formFullTitle('New Injection Moulded Lot'),
        },
      },
      {
        path: 'im-lot-table',
        component: () => import ('../views/manufacturing/datatables/ImLotTable.vue'),
        meta: {
          title: formFullTitle('Injection Moulded Lot Data'),
        },
      },
      {
        path: 'mould-catalogue',
        component: () => import ('../views/manufacturing/datatables/MouldCatalogueView.vue'),
        meta: {
          title: formFullTitle('Mould Catalogue')
        }
      },
      {
        path: 'part-catalogue',
        component: () => import ('../views/manufacturing/datatables/PartCatalogueView.vue'),
        meta: {
          title: formFullTitle('Part Catalogue')
        }
      },
      {
        path: 'printhead-catalogue',
        component: () => import ('../views/manufacturing/datatables/PrintheadCatalogueView.vue'),
        meta: {
          title: formFullTitle('Printhead Catalogue')
        }
      },
    ]
  },
  {
    path: '/manufacturing',
    name: 'manufacturing',
    component: () => import ('../views/manufacturing/ManufacturingFormsOverview.vue'),
    meta: {
      title: formFullTitle('Manufacturing'),
    },
    children: [
      // NOTE: commented out the following rediection because a user who doesn't have access permission to the hard-coded
      // redirection destination would be led to the insufficient permission error page
      // {
      //   path: '', // if the child path is unspecified, redirect to the hard-coded child
      //   redirect: '/manufacturing/mould-production-form'
      // },
      {
        path: 'mould-production-form',
        component: () => import ('../views/manufacturing/MouldProductionFormView.vue'),
        meta: {
          title: formFullTitle('Mould Production Form')
        }
      },
      {
        path: 'mould-preparation-form',
        component: () => import ('../views/manufacturing/MouldPreparationFormView.vue'),
        meta: {
          title: formFullTitle('Mould Preparation Form')
        }
      },
      {
        path: 'mould-inspection-report-form',
        component: () => import ('../views/manufacturing/MouldInspectionReportFormView.vue'),
        meta: {
          title: formFullTitle('Mould Inspection Report Form')
        }
      },
      {
        path: 'printhead-return-form',
        component: () => import ('../views/manufacturing/PrintheadReturnForm.vue'),
        meta: {
          title: formFullTitle('Printhead Return Form')
        }
      },
      {
        path: 'injection-moulded-part-form',
        component: () => import ('../views/manufacturing/InjectionMouldedPartEntryFormView.vue'),
        meta: {
          title: formFullTitle('Injection Moulded Part Form')
        }
      },
      {
        path: 'moulding-form',
        component: () => import ('../views/manufacturing/MouldingFormView.vue'),
        meta: {
          title: formFullTitle('Moulding Form')
        }
      },
      {
        path: 'demoulding-form',
        component: () => import ('../views/manufacturing/DemouldingFormView.vue'),
        meta: {
          title: formFullTitle('Demoulding Form')
        }
      },
      {
        path: 'thin-film-bonding-form',
        component: () => import ('../views/manufacturing/PartThinFilmBondingFormView.vue'),
        meta: {
          title: formFullTitle('Thin-film Bonding Form')
        }
      },
      {
        path: 'part-adjustment-intent-form',
        component: () => import ('../views/manufacturing/PartAdjustmentIntentFormView.vue'),
        meta: {
          title: formFullTitle('Part Adjustment Intent Form')
        }
      },
      {
        path: 'part-preparation-form',
        component: () => import ('../views/manufacturing/PartPreparationFormView.vue'),
        meta: {
          title: formFullTitle('Part Preparation Form')
        }
      },
      {
        path: 'part-inspection-report-form',
        component: () => import ('../views/manufacturing/PartInspectionReportFormView.vue'),
        meta: {
          title: formFullTitle('Part Inspection Report Form')
        }
      },
      {
        path: 'printhead-set-form',
        component: () => import ('../views/manufacturing/PrintheadSetFormView.vue'),
        meta: {
          title: formFullTitle('Printhead Set Production Form')
        }
      },
      {
        path: 'printhead-inspection-report',
        component: () => import ('../views/manufacturing/PrintheadInspectionReportView.vue'),
        meta: {
          title: formFullTitle('Printhead Inspection Report Form')
        }
      },
      {
        path: 'mould-status-data-table',
        component: () => import ('../views/manufacturing/datatables/MouldStatusDataTableView.vue'),
        meta: {
          title: formFullTitle('Mould Status Data Table')
        }
      },
      {
        path: 'part-status-data-table',
        component: () => import ('../views/manufacturing/datatables/PartStatusDataTableView.vue'),
        meta: {
          title: formFullTitle('Part Status Data Table')
        }
      },
      {
        path: 'printhead-status-data-table',
        component: () => import ('../views/manufacturing/datatables/PrintheadStatusDataTableView.vue'),
        meta: {
          title: formFullTitle('Printhead Status Data Table')
        }
      },
      {
        path: 'mould-details-data',
        component: () => import ('../views/manufacturing/datatables/MouldDetailsDataView.vue'),
        meta: {
          title: formFullTitle('Part Details Data')
        }
      },
      {
        path: 'mould-special-ticket-creation-form',
        component: () => import ('../views/manufacturing/MouldSpecialTicketCreationFormView.vue'),
        meta: {
          title: formFullTitle('Mould Special Ticket Form')
        }
      },
      {
        path: 'part-details-data',
        component: () => import ('../views/manufacturing/datatables/PartDetailsDataView.vue'),
        meta: {
          title: formFullTitle('Part Details Data')
        }
      },
      {
        path: 'part-special-ticket-creation-form',
        component: () => import ('../views/manufacturing/PartSpecialTicketCreationFormView.vue'),
        meta: {
          title: formFullTitle('Part Special Ticket Form')
        }
      },
      {
        path: 'printhead-details-data',
        component: () => import ('../views/manufacturing/datatables/PrintheadDetailsDataView.vue'),
        meta: {
          title: formFullTitle('Part Details Data')
        }
      },
      {
        path: 'printhead-special-ticket-creation-form',
        component: () => import ('../views/manufacturing/PrintheadSpecialTicketCreationFormView.vue'),
        meta: {
          title: formFullTitle('Printhead Special Ticket Form')
        }
      },
      {
        path: 'part-manufacturing-demand-overview-data',
        component: () => import ('../views/manufacturing/datatables/PartManufacturingDemandOverview.vue'),
        meta: {
          title: formFullTitle('Part Manufacturing Demand Overview')
        }
      },
      {
        path: 'printhead-manufacturing-demand-overview-data',
        component: () => import ('../views/manufacturing/datatables/PrintheadManufacturingDemandOverview.vue'),
        meta: {
          title: formFullTitle('Printhead Manufacturing Demand Overview')
        }
      },
      {
        path: 'mould-inventory-priorities',
        component: () => import ('../views/manufacturing/datatables/MouldInventoryPrioritiesDataTableView.vue'),
        meta: {
          title: formFullTitle('Mould Inventory Priority')
        }
      },
      {
        path: 'part-moulding-priorities',
        component: () => import ('../views/manufacturing/datatables/PartMouldingPrioritiesDataTableView.vue'),
        meta: {
          title: formFullTitle('Part Moulding Priority')
        }
      },
      {
        path: 'part-injection-moulding-priorities',
        component: () => import ('../views/manufacturing/datatables/PartInjectionMouldingPrioritiesDataTableView.vue'),
        meta: {
          title: formFullTitle('Part Injection Moulding Priority')
        }
      },
      {
        path: 'part-thin-film-priorities',
        component: () => import ('../views/manufacturing/datatables/PartThinFilmPrioritiesDataTableView.vue'),
        meta: {
          title: formFullTitle('Part Thin Film Priority')
        }
      },
      {
        path: 'part-adjustment-priorities',
        component: () => import ('../views/manufacturing/datatables/PartAdjustmentPrioritiesDataTableView.vue'),
        meta: {
          title: formFullTitle('Part Adjustment Priority')
        }
      },
      {
        path: 'part-preparation-priorities',
        component: () => import ('../views/manufacturing/datatables/PartPreparationPrioritiesDataTableView.vue'),
        meta: {
          title: formFullTitle('Part Preparation Priority')
        }
      },
      {
        path: 'part-inspection-priorities',
        component: () => import ('../views/manufacturing/datatables/PartInspectionPrioritiesDataTableView.vue'),
        meta: {
          title: formFullTitle('Part Inspection Priority')
        }
      },
      {
        path: 'printhead-set-priorities',
        component: () => import ('../views/manufacturing/datatables/PrintheadSetPrioritiesDataTableView.vue'),
        meta: {
          title: formFullTitle('Printhed Set Priority')
        }
      },
      {
        path: 'printhead-inspection-priorities',
        component: () => import ('../views/manufacturing/datatables/PrintheadInspectionPrioritiesDataTableView.vue'),
        meta: {
          title: formFullTitle('Printhed Inspection Priority')
        }
      },
      {
        path: 'part-priority-overview',
        component: () => import ('../views/manufacturing/datatables/PartPriorityOverview.vue'),
        meta: {
          title: formFullTitle('Part Priority Overview')
        }
      },
      {
        path: 'printhead-priority-overview',
        component: () => import ('../views/manufacturing/datatables/PrintheadPriorityOverview.vue'),
        meta: {
          title: formFullTitle('Printhed Priority Overview')
        }
      },
      {
        path: 'mould-kpi-data',
        component: () => import ('../views/manufacturing/datatables/MouldKpiDataView.vue'),
        meta: {
          title: formFullTitle('Mould KPI Data')
        }
      },
      {
        path: 'part-kpi-data',
        component: () => import ('../views/manufacturing/datatables/PartKpiDataView.vue'),
        meta: {
          title: formFullTitle('Part KPI Data')
        }
      },
      {
        path: 'printhead-kpi-data',
        component: () => import ('../views/manufacturing/datatables/PrintheadKpiDataView.vue'),
        meta: {
          title: formFullTitle('Printhead KPI Data')
        }
      }
    ]
  },
  {
    path: '/manufacturing-investigation',
    name: 'manufacturing-investigation',
    component: () => import ('../views/manufacturing/ManufacturingInvestigationFormsOverview.vue'),
    meta: {
      title: formFullTitle('Manufacturing Investigation'),
    },
    children: [
      // NOTE: commented out the following rediection because a user who doesn't have access permission to the hard-coded
      // redirection destination would be led to the insufficient permission error page
      // {
      //   path: '', // if the child path is unspecified, redirect to the hard-coded child
      //   redirect: '/manufacturing-investigation/mould-investigation-report-form'
      // },
      {
        path: 'mould-investigation-report-form',
        component: () => import ('../views/manufacturing/MouldInvestigationReportFormView.vue'),
        meta: {
          title: formFullTitle('Mould Investigation Form')
        }
      },
      {
        path: 'part-investigation-report-form',
        component: () => import ('../views/manufacturing/PartInvestigationReportFormView.vue'),
        meta: {
          title: formFullTitle('Part Investigation Form')
        }
      },
      {
        path: 'printhead-investigation-report',
        component: () => import ('../views/manufacturing/PrintheadInvestigationReportFormView.vue'),
        meta: {
          title: formFullTitle('Printhead Investigation Form')
        }
      },
      {
        path: 'mould-failure-data',
        component: () => import ('../views/manufacturing/MouldFailureDataView.vue'),
        meta: {
          title: formFullTitle('Mould Failure Data')
        }
      },
      {
        path: 'part-failure-data',
        component: () => import ('../views/manufacturing/PartFailureDataView.vue'),
        meta: {
          title: formFullTitle('Part Failure Data')
        }
      },
      {
        path: 'printhead-failure-data',
        component: () => import ('../views/manufacturing/PrintheadFailureDataView.vue'),
        meta: {
          title: formFullTitle('Printhead Failure Data')
        }
      },
    ]
  },
  {
    path: '/administration',
    name: 'administration',
    component: () => import('../views/admin/AdministrationView.vue'),
    meta: {
      title: formFullTitle('Administration'),
    },
    children: [
      {
        path: '', // if the child path is unspecified, redirect to the hard-coded child
        redirect: '/administration/user-scope-management'
      },
      {
        path: 'user-scope-management',
        component: () => import('../views/admin/UserScopeManagementView.vue')
      },
    ],
  },
]